<template>
  <div id="app">
    <el-button type="primary" @click="handleRemoteAssistance"
      >远程协助</el-button
    >
    <el-dialog
      :modelValue="visible"
      width="100%"
      center
      :custom-class="'remote-assistance-dialog'"
      :close-on-click-modal="false"
    >
      <div ref="wrapper" class="wrapper" v-show="!showLoading"></div>
      <div v-show="showLoading" class="msg-placeholder" id="msg">
        <div class="loading" id="loading-animate">
          <span></span><span></span><span></span><span></span><span></span>
        </div>
        <span id="msg-text" class="msg-text">{{ msgText }}</span>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
const SunloginControl = window.SunloginControl

const store = useStore()
let client = null
let keyboardHandler = null
let mouseHandler = null

const showLoading = ref(true)
const visible = ref(false)
let totalBytes = 0

let view = null
let framesCount = 0
const msgText = ref('正在加载程序...')
const wrapper = ref(null)

function handleRemoteAssistance() {
  console.log(window.SunloginControl)
  visible.value = true
  initSunlogin({
    address: store.state.terminal.currentAddress,
    cid: store.state.terminal.currentSession
  })
}
function initSunlogin({ address, cid }) {
  const reg = /PHSRC_(HTTP|HTTPS):\/\/([^/]+):(\d+)*\//
  const matches = address.match(reg)
  client = new SunloginControl.Client({
    host: matches[2],
    embed: true,
    ssl: matches[1].indexOf('HTTPS') !== -1,
    cid,
    port: matches[3],
    debug: false
  })
  SunloginControl.Wasm.preload().then(() => {
    client
      .connect()
      .then(() => {
        showLoading.value = false
        client.on('disconnect', () => {
          showLoading.value = true
          view = null
          // delete view
          visible.value = false

          if (keyboardHandler) {
            keyboardHandler.stop()
            keyboardHandler = null
          }

          if (mouseHandler) {
            mouseHandler.stop()
            mouseHandler = null
          }
        })
        view = new SunloginControl.Wasm(wrapper.value)
        const desktop = new SunloginControl.Plugin.Desktop({
          quality: 10
        })
        client.connectPlugin(desktop)
        desktop.on('imageinfo', (info) => {
          console.log(info)
          view.setImageInfo(info)
        })
        desktop.on('screen', (count) => {
          console.log('屏幕数量：' + count)
        })
        desktop.on('session', (sessions) => {
          console.log('系统会话：', sessions)
        })
        desktop.on('stream', (stream, info) => {
          framesCount = framesCount++
          totalBytes = totalBytes += info.length
          view.decode(stream, info)
          desktop.sendDecodeFinishMessage(framesCount, totalBytes)
        })
        mouseHandler = desktop.transportMouseEvent(wrapper.value)
        keyboardHandler = desktop.transportKeyBoardEvent(wrapper.value)
      })
      .catch((e) => {
        console.log('Error occured: ', e)
        client.disconnect()
      })
  })
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #2c3e50;
}
.wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
}
.remote-assistance-dialog .el-dialog__body {
  padding: 0;
}
.msg-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.msg-placeholder .msg-text {
  color: #fff;
}
.msg-placeholder .loading {
  height: 20px;
  margin-right: 10px;
  display: inline-block;
}
.msg-placeholder .loading span {
  display: inline-block;
  width: 8px;
  height: 100%;
  border-radius: 4px;
  margin: 0 1px;
  background: #ff8a56;
  animation: load 1s ease infinite;
}
.msg-placeholder .loading span:nth-child(2) {
  animation-delay: 0.2s;
}
.msg-placeholder .loading span:nth-child(3) {
  animation-delay: 0.4s;
}
.msg-placeholder .loading span:nth-child(4) {
  animation-delay: 0.6s;
}
.msg-placeholder .loading span:nth-child(5) {
  animation-delay: 0.8s;
}
@keyframes load {
  0%,
  100% {
    height: 10px;
    background: #ff8a56;
  }
  50% {
    height: 20px;
    margin: -5px 1px;
    background: #ff895679;
  }
}
</style>
